<template :key="keyId">
  <Content>
    <ErrorNotificationModal
    :showingModal="showModal"
    :error-message="errorMessage"
    @redirect="confirmModal"
    />
    <PersonalityCarousel
      v-if="readySliders"
      :invitation="invitation"
      :slides="slides" @changeSlide="changeSlide($event)"/>
  </Content>
</template>

<script>

import personalStrengths from '@assets/personal-strengths.svg';
import trustedSupporters from '@assets/trusted-supporters.svg';
import welcome from '@assets/invitation-slider-welcome.svg';
import anonymous from '@assets/invitation-slider-anonymous.svg';
import supporter from '@assets/invitation-slider-supporter.svg';
import yourStrengthsProfile from '@assets/your-strengths-profile.svg';
import PersonalityCarousel from '@components/PersonalityCarousel/PersonalityCarousel.vue';
import ErrorNotificationModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ErrorNotificationModal/ErrorNotificationModal.vue';
import { mapGetters } from 'vuex';
import helpFunction from '@helpers/helpFunction';

export default {
  name: 'UserInformation',
  components: {
    PersonalityCarousel,
    ErrorNotificationModal,
  },
  computed: {
    ...mapGetters({
      getInvitationData: 'invitation/getInvitationData',
      getProfile: 'auth/getProfile',
    }),
  },
  created() {
    if (!helpFunction.isFillObject(this.getInvitationData)) {
      this.invitation = false;
      this.slides = [
        {
          id: 0,
          src: personalStrengths,
          type: 'usual',
          title: '<b>1</b>/3 <b>PERSONAL STRENGTHS</b>',
          desc: 'Assess yourself to <b>get a summary</b> of where you see your strengths',
          textButton: 'NEXT',
        },
        {
          id: 1,
          src: trustedSupporters,
          type: 'usual',
          title: '<b>2</b>/3 <b>TRUSTED SUPPORTERS</b>',
          desc: 'Let at least <b>4 people</b> you trust support where they see your strengths',
          textButton: 'NEXT',
        },
        {
          id: 2,
          src: yourStrengthsProfile,
          type: 'usual',
          title: '<b>3</b>/3 <b>YOUR STRENGTHS PROFILE</b>',
          desc: 'With your trusted supporters anonymous reviews combined with your own, you’ll see your <b>true strengths</b>',
          textButton: 'LET’S DO THIS!',
        },
        {
          id: 3,
          type: 'landing',
        },
      ];
      this.readySliders = true;
    } else {
      this.fetchInvitationQuestionnaire(this.getInvitationData.userId);
    }
  },
  data: () => ({
    personalStrengths,
    trustedSupporters,
    yourStrengthsProfile,
    welcome,
    supporter,
    anonymous,
    invitation: false,
    slides: [{}],
    userName: '',
    currentSlide: 0,
    readySliders: false,
    showModal: false,
    errorMessage: '',
    keyId: 0,
  }),
  methods: {
    changeSlide(numberSlide) {
      this.currentSlide = numberSlide;
    },
    confirmModal() {
      this.$router.push('main').catch(() => {});
      this.showModal = false;
      if (this.getProfile.completedQuestionnaires
        && this.getProfile.completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
        this.$router.push('result');
      } else {
        this.$emit('rerender');
      }
    },
    async fetchInvitationQuestionnaire(id) {
      try {
        let u1Id = localStorage.getItem('uniqueId');

        if (this.getProfile.token) {
          u1Id = this.getProfile.id;
        }

        const data = await this.$api.questionnaire.fetchInvitationQuestionnaire(id, u1Id);
        this.userName = data.userName;
        this.setSliderInvitation();
        this.readySliders = true;
      } catch (e) {
        this.showModal = true;
        this.errorMessage = e.response.data.message;
        await this.$store.dispatch('invitation/setInvitationData', {});
      }
    },
    setSliderInvitation() {
      this.invitation = true;
      this.slides = [
        {
          id: 0,
          src: welcome,
          type: 'usual',
          title: '<b>WELCOME, CHOSEN ONE!</b>',
          desc: `${this.userName} chose you to support them in finding their <b>hidden strengths</b>`,
          textButton: 'NEXT',
        },
        {
          id: 1,
          src: supporter,
          type: 'usual',
          title: '<b>YOU’RE ANONYMOUS</b>',
          desc: 'We will never disclose your scores and it will be grouped with at <b>least 4</b> other scores',
          textButton: 'NEXT',
        },
        {
          id: 2,
          src: anonymous,
          type: 'usual',
          title: '<b>YOU’RE A SUPPORTER</b>',
          desc: 'The strengths you see might differ.'
            + 'There’s <b>nothing negative</b> about that.'
            + 'It will broaden the strength profile',
          textButton: 'LET’S DO THIS!',
        },
        {
          id: 3,
          type: 'landing',
        },
      ];
    },
  },
};
</script>

<style scoped>

</style>
