<template>
  <div class="onBoarding">
    <Content>
      <UserInformation
        @rerender="rerenderComponent"
        :key="keyId"
        ref="welcome"
        v-if="loading"/>
      <LoadingScreen @finished-loading="finishedLoading" v-else/>
    </Content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userBehave from '@constants/userBehave';
import UserInformation from '@views/UserInformation.vue';
import LoadingScreen from '@innerworks_ds/innerworks-storybook-main/src/stories/Loader/LoadingScreen/LoadingScreen.vue';

export default {
  components: {
    LoadingScreen,
    UserInformation,
  },
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      keyId: 0,
    };
  },
  updated() {
    this.$store.dispatch('showComponent/turnOffHeader');
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getPersonalityTest: 'invitation/getPersonalityTest',
      getInvitationData: 'invitation/getInvitationData',
    }),
  },
  watch: {
    login: {
      immediate: true,
      handler() {
        if (this.login) {
          this.loading = true;
        }
      },
    },
  },
  methods: {
    rerenderComponent() {
      this.keyId += 1;
    },
    finishedLoading(value) {
      this.loading = value;
    },
  },
  mounted() {
    this.$store.dispatch('showComponent/turnOffHeader');
    this.$api.userBehaveSite.saveSiteEvent(userBehave.mainPage);
  },
  name: 'Main',
};
</script>

<style lang="scss" scoped>
  .onBoarding {
    height: 100%;
  }
  .container {
    height: 100%;
  }
</style>
